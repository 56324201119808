import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { signIn } from 'aws-amplify/auth';

import { FaUser, FaLock } from "react-icons/fa";
import './LoginRegister.css';

import Button from 'react-bootstrap/Button';

function LoginPage() {
    const navigate = useNavigate()
    
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async (e) => {
        try {
            e.preventDefault();
            console.log('Login');
            console.log(username);   

            const { isSignedIn } = await signIn({username, password});
            
        } catch (err) { console.log(err) }
    }

    return (
        <div className={"wrapper"}>
            <div className="form-box login">
                <form action="">
                    <h1>Login</h1>
                    <div className="input-box">
                        <input type="text" placeholder='Enter User Name' required onChange={evt => setUserName(evt.target.value)}  />
                        <FaUser className='icon' />
                    </div>
                    <div className="input-box">
                        <input type="password" placeholder='Enter Password' required onChange={evt => setPassword(evt.target.value)} />
                        <FaLock className='icon' />
                    </div>
                    
                    <Button type="submit" onClick={handleLogin}>Login</Button>
                    <div className="button-container">
                        <Link to='/register'>
                            <Button variant="outline-primary">Register</Button>
                        </Link>
                        <Link to='/'>
                            <Button variant="outline-primary">Cancel</Button>
                        </Link>
                    </div>
                </form>    
            </div>
        </div>
    )
}

export default LoginPage;