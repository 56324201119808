import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function NotFound(props) {
    return (
        <Container>
            <Row className="px-4 my-5">
                <Col sm={6}>
                    <h1 className="font-weight-light">Not Found</h1>
                </Col>
            </Row>
        </Container >
    )
}

export default NotFound;