import logo from './logo.svg';
import './App.css';
import { getCurrentUser } from 'aws-amplify/auth';
import 'bootstrap/dist/css/bootstrap.min.css'
import '@aws-amplify/ui-react/styles.css';

import { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';

import SiteFooter from './components/common/SiteFooter';
import NotFound from './components/error/NotFound';
import LoginPage from './components/auth/LoginPage';
import RegisterPage from './components/auth/RegisterPage';
import ValidatePage from './components/auth/ValidatePage';
import QuestionForm from './components/questionform/QuestionForm';
import { Hub } from 'aws-amplify/utils';

// var awsExports = {};
Amplify.configure(awsExports);


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  Hub.listen('auth', ({ payload }) => {
    switch (payload.event) {
      case 'signedIn':
        setIsAuthenticated(true);
        break;
      case 'signedOut':
        setIsAuthenticated(false);
        break;
      case 'signInWithRedirect':
        setIsAuthenticated(true);
        break;
      case 'signInWithRedirect_failure':
        setIsAuthenticated(false);
        break;
    }
  });

  const checkAuth = async () => {
    try {
      const user = await getCurrentUser();
      setIsAuthenticated(user ? true : false);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    checkAuth();
  }, [checkAuth])

  return (
    <div>
      <Routes>
        {!isAuthenticated && <Route path='/' element={<LoginPage />} />}
        {isAuthenticated && <Route path='/' element={<QuestionForm isAuthenticated={isAuthenticated} />} />}
        {!isAuthenticated && <Route path='/register' element={<RegisterPage />} />}
        {!isAuthenticated && <Route path='/validate' element={<ValidatePage />} />}
        <Route path='*' element={<NotFound />} />
      </Routes>
      <SiteFooter />
    </div>
  )
};

export default App;
