import { useNavigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

function SiteNav(props) {
    const navigate = useNavigate()
    
    const handleLogout = async () => {
        try {
            console.log('Logout');
            await signOut();
        } catch (err) { console.log(err) }
    }

    return (
        <Container>
            <nav className="float-end">
                <Button variant="primary" onClick={handleLogout}>Logout</Button>
            </nav>
        </Container>
    )
}

export default SiteNav;