import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import SiteNav from '../common/SiteNav';
import axios from 'axios';
import { BounceLoader, PropagateLoader } from 'react-spinners';
import Button from 'react-bootstrap/Button';
import { FaSearch } from 'react-icons/fa';
import Nav from 'react-bootstrap/Nav'; //Added the Nav
import Navbar from 'react-bootstrap/Navbar'; //Added the Navbar
import './questionform.css'; //Added the custom css file
import pic from '../assets/questionimage.jpg'; //Added the image file
import '../auth/LoginRegister.css';



const api = axios.create({
    baseURL: 'https://backend.manuai.ai' //'http://3.123.18.251'
})


function QuestionForm(props) {

    //  Function for the style of the answer when it is empty
  const answerStyle = () => {
    if (answer === "") {
      return {
        padding: "20px 20px",
        borderRadius: "75px",
        backgroundColor: "rgba(0, 81, 255, 0.8)",  /* The last value is the opacity you can change the color by changing the other values (For when there is no text) */
        wordWrap: "break-word",
        whiteSpace: "pre-wrap", 
        textAlign:"left",
        width: "150%" 
      };
    }
    else{
      return {
        padding: "70px",
        borderRadius: "75px",
        backgroundColor: "rgba(0, 81, 255, 0.8)",  /* The last value is the opacity you can change the color by changing the other values (For when there is text) */
        wordWrap: "break-word",
        whiteSpace: "pre-wrap",
        textAlign:"left",
        width: "80%" 
      };
    }
  };

    const navigate = useNavigate()


    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        {
            props.isAuthenticated !== true && (
                navigate('/')
            )
        }
    }, []);


    const handleSubmit = async (e) => {
      e.preventDefault(); // Prevent default form submission early
      if (!question) {
          setAnswer(""); // Reset the answer if no question is provided
          return;
      }
      try {
          setIsLoading(true); // Set loading state before making the request
          console.log("Your question: ", question);
          
          const response = await api.post("/chat_with_knowledge_base?query=" + question);
          
          console.log(response);
          setAnswer(response.data); // Set the answer with the response data
      } catch (error) {
          console.error("Error occurred while fetching data: ", error); // Error handling
          setAnswer("An error occured in retrieving the response");
      } finally {
          setIsLoading(false); // Reset loading state after the request completes
      }
    };
    

    return (
        <div className="App">



        <Navbar expand="lg" className="custom-navbar sticky-top" >
            {/* Brand section with an image */}
            <Navbar.Brand >
            <img
              src={pic} 
              alt="Brand Logo"
              style={{ height: '70px',width:"70px",objectFit:"cover", borderRadius:"100px" }} // Adjust the height as needed
            />
            </Navbar.Brand>
    
            {/* Toggle for mobile view */}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
    
            {/* Collapse section for navigation items */}
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                {/* No links in this example */}
              </Nav>
    
              {/* Logout button on the extreme right */}
              <SiteNav  />
            </Navbar.Collapse>
        </Navbar>




       <header className="App-header" style={{marginTop:"120px"}}>  {/*Adjust the marginTop value to move the content between the header tags upwards or downwards (Negative value for upwards and positive for downwards) */}

      
      
 
        <p style={{marginBottom:"10px"}}>
          Welcome to Miami
        </p>
      
        


        <div className="search-container">
            <div className="search-wrapper">
                   <input type="text" className="search-input" placeholder="Search..." onChange={(e) => setQuestion(e.target.value)} />
                  <button className="search-button" onClick={handleSubmit}>
                  <FaSearch className="search-icon" style={{color: '#0d6efd'}} />
                  </button>
            </div>
        </div>

        <div className="main-container" >
            <form className="form">             
                <Button variant="primary" className="form-button" type="submit" onClick={handleSubmit} style={{marginTop:"40px"}}>Submit</Button>
            </form>
            {isLoading && (
                <div className="loader-container">
                    <PropagateLoader color="#4dd2ff" />
                </div>
            )}
            
            
            <div className="results-answer">
                <h2>Answer</h2>
                <p className='answer' style={answerStyle()}>{answer.trimStart()}</p>
            </div>
                    
        </div>

        </header>
   
    </div>


        
    );
}

export default QuestionForm;
