import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { signUp, autoSignIn } from 'aws-amplify/auth';

import Button from 'react-bootstrap/Button';

import { FaUser, FaLock, FaEnvelope } from "react-icons/fa";
import './LoginRegister.css';

function RegisterPage() {
    const navigate = useNavigate()

    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            const { isSignUpComplete, nextStep } = await signUp({
                username: username,
                password: password,
                options: {
                    userAttributes: {
                      email: email              
                    }
                }
            });

            if (!isSignUpComplete && nextStep.signUpStep === "CONFIRM_SIGN_UP") {
                navigate('/validate')
                return;
            }

            if (isSignUpComplete && nextStep.signUpStep === "COMPLETE_AUTO_SIGN_IN") {
                await autoSignIn()
                return;
            }
        
            if (isSignUpComplete && nextStep.signUpStep === "DONE") {
                navigate('/')
                return;
            }
            throw new Error("Unable to handle registration state.");
        } catch (err) { console.log(err) }
    }

    return (
        <div className={"wrapper"}>
            <div className="form-box register">
                <form action="">
                    <h1>Register</h1>
                    <div className="input-box">
                        <input type="text" placeholder='Enter User Name' required onChange={evt => setUserName(evt.target.value)}  />
                        <FaUser className='icon' />
                    </div>
                    <div className="input-box">
                        <input type="email" placeholder='Enter Email Address' required onChange={evt => setEmail(evt.target.value)} />
                        <FaEnvelope className='icon' />
                    </div>
                    <div className="input-box">
                        <input type="password" minLength="8" placeholder='Enter Password' onChange={evt => setPassword(evt.target.value)} required />
                        <FaLock className='icon' />
                    </div>

                    <Button type="submit" onClick={handleRegister}>Register</Button>

                    <div className="button-container">    
                        <Link to='/'>
                            <Button variant="outline-primary">Login</Button>
                        </Link>
                        
                        <Link to='/'>
                            <Button variant="outline-primary">Cancel</Button>
                        </Link>
                    </div>
                </form>    
            </div>
        </div>
    )
}

export default RegisterPage;