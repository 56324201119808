import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { confirmSignUp } from 'aws-amplify/auth';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { FaUser, FaClipboardCheck } from "react-icons/fa";

function ValidatePage() {    
    const navigate = useNavigate()
    const [username, setUserName] = useState('');
    const [authenticationCode, setAuthenticationCode] = useState('');
    

    const handleRegisterConfirmation = async (e) => {
        e.preventDefault();
        try {
            console.log('handleRegisterConfirmation')

            const { isSignUpComplete, nextStep } = await confirmSignUp({
                username: username, 
                confirmationCode: authenticationCode
            });

            if (isSignUpComplete && nextStep.signUpStep === "DONE") {
                navigate('/')
                return;
            }
        } catch (err) { console.log(err) }
    }

    return (
        <div className={"wrapper"}>
            <div className="form-box login">
                <form action="">
                    <h1>Validate</h1>
                    <div className="input-box">
                        <input type="text" placeholder='Enter User Name' required onChange={evt => setUserName(evt.target.value)}  />
                        <FaUser className='icon' />
                    </div>
                    <div className="input-box">
                        <input type="password" placeholder='Enter Code' required onChange={evt => setAuthenticationCode(evt.target.value)} />
                        <FaClipboardCheck className='icon' />
                    </div>
                    
                    <Button type="submit" onClick={handleRegisterConfirmation}>Validate &gt;&gt;</Button>
                    &nbsp;&nbsp;
                    <Link to='/'>
                        <Button variant="outline-primary">Cancel</Button>
                    </Link>
              
                </form>    
            </div>
        </div>

    )
}

export default ValidatePage;